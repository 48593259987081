export class ProjectRoom {
  id: string;
  project: string;
  roomType: string;
  name: string;
  floor: number;
  size: number;
  height: number;
  numberWindows: number;
  windowsInsulated: boolean;
  subFloorUnheated: boolean;
  supFloorUnheated: boolean;
  plasterInsteadCeiling: boolean;
  numberOuterWalls: number;
  numberDoorsInside: number;
  mountingStyle: string;

  constructor(id?: string, name?: string, size?: number, height?: number) {
    this.id = id;
    this.name = name;
    this.size = size;
    this.height = height;
  }
}
