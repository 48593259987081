export class Customer {
  id: string;
  partner: string;
  firstname: string;
  surname: string;
  companyName: string;
  uid: string;
  registerNumber: string;
  street: string;
  zipCode: string;
  city: string;
  country: string;
  phone: string;
  email: string;

  constructor();

  constructor(
    id?: string,
    partner?: string,
    firstname?: string,
    surname?: string,
    companyName?: string,
    uid?: string,
    registerNumber?: string,
    street?: string,
    zipCode?: string,
    city?: string,
    country?: string,
    phone?: string,
    email?: string) {
    this.id = id;
    this.firstname = firstname;
    this.surname = surname;
    this.companyName = companyName;
    this.uid = uid;
    this.registerNumber = registerNumber;
    this.street = street;
    this.zipCode = zipCode;
    this.city = city;
    this.phone = phone;
    this.email = email;
  }
}
