import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {BuildingType} from '../models/BuildingType';
import {environment} from '../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {Customer} from '../models/Customer';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {Project} from '../models/Project';
import {ProjectRoom} from '../models/project-room.model';
import {RoomType} from '../models/RoomType';

@Component({
  selector: 'app-root',
  providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  gdpr: boolean;

  id: string;
  customer: Customer = new Customer();
  project: Project = new Project();

  customerForm: FormGroup;
  projectForm: FormGroup;

  firstCustomerChange = false;
  buildingTypes: BuildingType[];
  roomTypes: RoomType[];

  projectRooms: Array<{ room: ProjectRoom, form: FormGroup }> = new Array();

  openProjectRoomIndex = -1;

  constructor(private formBuilder: FormBuilder, private http: HttpClient, private route: ActivatedRoute, private location: Location) {
  }

  ngOnInit() {
    this.customerForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      surname: ['', Validators.required],
      companyName: [''],
      street: ['', Validators.required],
      zipCode: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      phone: [''],
      email: ['', Validators.required],
      gdpr: [false, Validators.requiredTrue]
    });

    this.projectForm = this.formBuilder.group({
      name: ['', Validators.required],
      buildingType: [null, Validators.required],
      altitude: [0],
      street: ['', Validators.required],
      zipCode: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
    });

    this.http.get<BuildingType[]>(environment.apiUrl + '/buildingTypes/allForUnassigned')
      .subscribe(buildingTypes => {
        this.buildingTypes = buildingTypes;

        this.projectForm.get('buildingType').setValue(buildingTypes[0].id);
      });

    this.http.get<RoomType[]>(environment.apiUrl + '/projectRoomTypes/allForUnassigned')
      .subscribe(roomTypes => {
        this.roomTypes = roomTypes;
        this.roomTypes.splice(this.roomTypes.indexOf(this.roomTypes.find(r => r.name.search('/wellness/gi') !== -1)));
        this.roomTypes.splice(this.roomTypes.indexOf(this.roomTypes.find(r => r.name.search('/bodenheiz/gi') !== -1)));
      });

    this.route.queryParams.subscribe(params => {
      this.id = params['id'];

      if (this.id) {
        this.http.get<Customer>(environment.apiUrl + '/customers/unassigned/' + this.id).subscribe(customer => {
          this.customer = customer;

          if (this.customer) {
            this.setCustomerToForm();

            this.http.get<Project>(environment.apiUrl + '/projects/forUnassigned/' + this.id).subscribe(project => {
              this.project = project;

              this.setProjectForm();

              this.http.get<ProjectRoom[]>(environment.apiUrl + '/projectRooms/forProjectForUnassigned/' + this.project.id).subscribe(projectRooms => {
                this.projectRooms = new Array();

                projectRooms.forEach(p => {
                  this.projectRooms.push({
                    room: p,
                    form: this.formBuilder.group({
                      name: ['Neuer Raum', Validators.required],
                      roomType: [this.roomTypes[0].id, Validators.required],
                      floor: [0, Validators.required],
                      size: [0, Validators.required],
                      height: [0, Validators.required],
                      numberWindows: [0, Validators.required],
                      numberOuterWalls: [0, Validators.required],
                      numberDoorsInside: [0, Validators.required],
                      windowsInsulated: [true, Validators.required],
                      subFloorUnheated: [true, Validators.required],
                      supFloorUnheated: [true, Validators.required]
                    })
                  });
                });

                this.setProjectRoomForms();
              });
            });
          }
        });
      }
    });
  }

  addEmptyRoom() {

    this.projectRooms.push({
      room: new ProjectRoom('' + ((this.projectRooms.length + 1) * -1)),
      form: this.formBuilder.group({
        name: ['Neuer Raum', Validators.required],
        roomType: [this.roomTypes[0].id, Validators.required],
        floor: [0, [Validators.required, Validators.min(0)]],
        size: [0, [Validators.required, Validators.min(0)]],
        height: [0, [Validators.required, Validators.min(0)]],
        numberWindows: [0, [Validators.required, Validators.min(0)]],
        numberOuterWalls: [0, [Validators.required, Validators.min(0)]],
        numberDoorsInside: [0, [Validators.required, Validators.min(0)]],
        windowsInsulated: [true, Validators.required],
        subFloorUnheated: [true, Validators.required],
        supFloorUnheated: [true, Validators.required]
      })
    });

    this.projectRooms[this.projectRooms.length - 1].form.markAsDirty();

    this.openProjectRoomIndex = this.projectRooms.length - 1;
  }

  deleteProjectRoom(projectRoom) {
    if (projectRoom.room.id && projectRoom.room.id.length > 4) {
      this.http.delete<boolean>(environment.apiUrl + '/projectRooms/deleteForUnassigned/' + projectRoom.room.id)
        .subscribe(result => {
          if (result) {
            this.projectRooms.splice(this.projectRooms.indexOf(projectRoom), 1);
          }
        });
    } else {
      this.projectRooms.splice(this.projectRooms.indexOf(projectRoom), 1);
    }
  }

  getRoomTypeName(id: string) {
    return this.roomTypes.find(r => r.id === id).name;
  }

  saveProjectRoom(projectRoom) {
    if (projectRoom.form.valid) {

      projectRoom.room.name = projectRoom.form.get('name').value;
      projectRoom.room.roomType = projectRoom.form.get('roomType').value;
      projectRoom.room.height = projectRoom.form.get('height').value;
      projectRoom.room.floor = projectRoom.form.get('floor').value;
      projectRoom.room.size = projectRoom.form.get('size').value;
      projectRoom.room.numberWindows = projectRoom.form.get('numberWindows').value;
      projectRoom.room.numberOuterWalls = projectRoom.form.get('numberOuterWalls').value;
      projectRoom.room.numberDoorsInside = projectRoom.form.get('numberDoorsInside').value;
      projectRoom.room.windowsInsulated = projectRoom.form.get('windowsInsulated').value;
      projectRoom.room.supFloorUnheated = projectRoom.form.get('supFloorUnheated').value;
      projectRoom.room.subFloorUnheated = projectRoom.form.get('subFloorUnheated').value;
      projectRoom.room.project = this.project.id;

      let url = '/projectRooms/createForUnassigned';

      if (projectRoom.room.id && projectRoom.room.id.length > 4) {
        url = '/projectRooms/updateForUnassigned';
      }

      this.http.post<ProjectRoom>(environment.apiUrl + url, projectRoom.room).subscribe(updatedRoom => {

        this.projectRooms.find(r => r.room.id === projectRoom.room.id).room = updatedRoom;

        this.setProjectRoomForms();
      });

      this.openProjectRoomIndex = -1;
    }
  }

  setProjectRoomForms() {
    this.projectRooms.forEach(p => {
      p.form.get('name').setValue(p.room.name);
      p.form.get('roomType').setValue(p.room.roomType);
      p.form.get('height').setValue(p.room.height);
      p.form.get('floor').setValue(p.room.floor);
      p.form.get('size').setValue(p.room.size);
      p.form.get('numberWindows').setValue(p.room.numberWindows);
      p.form.get('numberOuterWalls').setValue(p.room.numberOuterWalls);
      p.form.get('windowsInsulated').setValue(p.room.windowsInsulated);
      p.form.get('supFloorUnheated').setValue(p.room.supFloorUnheated);
      p.form.get('subFloorUnheated').setValue(p.room.subFloorUnheated);
      p.form.get('numberDoorsInside').setValue(p.room.numberDoorsInside);
      p.form.markAsPristine();
    });
  }

  setCustomerToForm() {
    this.customerForm.get('firstname').setValue(this.customer.firstname);
    this.customerForm.get('surname').setValue(this.customer.surname);
    this.customerForm.get('companyName').setValue(this.customer.companyName);
    this.customerForm.get('street').setValue(this.customer.street);
    this.customerForm.get('zipCode').setValue(this.customer.zipCode);
    this.customerForm.get('city').setValue(this.customer.city);
    this.customerForm.get('country').setValue(this.customer.country);
    this.customerForm.get('phone').setValue(this.customer.phone);
    this.customerForm.get('email').setValue(this.customer.email);
  }

  setProjectForm() {
    this.projectForm.get('name').setValue(this.project.name);
    this.projectForm.get('buildingType').setValue(this.project.buildingType);
    this.projectForm.get('altitude').setValue(this.project.altitude);
    this.projectForm.get('street').setValue(this.project.street);
    this.projectForm.get('zipCode').setValue(this.project.zipCode);
    this.projectForm.get('city').setValue(this.project.city);
    this.projectForm.get('country').setValue(this.project.country);
  }

  checkIfProjectRoomFormsValid() {
    let result = true;

    this.projectRooms.forEach(p => {
      result = result && p.form.valid && !p.form.dirty;
    });


    return result;
  }

  selectionChange($event) {
    if ($event.previouslySelectedIndex === 0) {

      this.customer.firstname = this.customerForm.get('firstname').value;
      this.customer.surname = this.customerForm.get('surname').value;
      this.customer.companyName = this.customerForm.get('companyName').value;
      this.customer.street = this.customerForm.get('street').value;
      this.customer.zipCode = this.customerForm.get('zipCode').value;
      this.customer.city = this.customerForm.get('city').value;
      this.customer.country = this.customerForm.get('country').value;
      this.customer.phone = this.customerForm.get('phone').value;
      this.customer.email = this.customerForm.get('email').value;

      let url = '/customers/createUnassigned';

      if (this.id) {
        url = '/customers/updateUnassigned';
      }

      this.http.post<Customer>(environment.apiUrl + url, this.customer).subscribe(updatedCustomer => {
        this.customer = updatedCustomer;
        this.id = updatedCustomer.id;
        this.location.go('?id=' + this.id);

        this.setCustomerToForm();
      });
    }

    if ($event.previouslySelectedIndex === 1 && this.projectForm.valid) {

      this.project.name = this.projectForm.get('name').value;
      this.project.buildingType = this.projectForm.get('buildingType').value;
      this.project.altitude = this.projectForm.get('altitude').value;
      this.project.street = this.projectForm.get('street').value;
      this.project.zipCode = this.projectForm.get('zipCode').value;
      this.project.city = this.projectForm.get('city').value;
      this.project.country = this.projectForm.get('country').value;
      this.project.customer = this.id;

      let url = '/projects/createForUnassigned';

      if (this.project.id) {
        url = '/projects/updateForUnassigned';
      }

      this.http.post<Project>(environment.apiUrl + url, this.project).subscribe(updatedProject => {
        this.project = updatedProject;

        this.setProjectForm();
      });
    }

    if ($event.previouslySelectedIndex === 0 && !this.firstCustomerChange && !this.customer.id) {
      this.firstCustomerChange = true;
      this.projectForm.get('street').setValue(this.customerForm.get('street').value);
      this.projectForm.get('zipCode').setValue(this.customerForm.get('zipCode').value);
      this.projectForm.get('city').setValue(this.customerForm.get('city').value);
      this.projectForm.get('country').setValue(this.customerForm.get('country').value);
    }
  }
}
